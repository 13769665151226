import { Environment } from './environment';

export class Configuration {
    /**
     * Returns environment configuration.
     */
    public static async getEnvironment(): Promise<Environment> {

        const env = new Environment();
        
        const reponse = await fetch('/configuration.json');

        if (reponse.ok) {
            const data = await reponse.json();
            env.ExploreUrl = data.ExploreUrl;
            env.ComputeUrl = data.ComputeUrl;
            env.GoogleTagManagerId = data.GoogleTagManagerId;
        } else {
            env.ExploreUrl = window['ExploreUrl'];
            env.ComputeUrl = window['ComputeUrl'];
            env.GoogleTagManagerId = window['GoogleTagManagerId'];
        }

        return env;
    }
}