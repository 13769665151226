<template>
  <div class="cookie">
    <div v-if="isOpen" class="block bg-dark-blue block-inverse block-shadow">
      <div class="row" align="center">
        <div class="col-md-9">
          <p>We use cookies for analytics tools to give you the best possible
          experience on our site. You can enable or disable cookies by clicking
          on the buttons. For more information on how and which cookies we use
          please read our 
          <router-link to="/CookieInformation">Cookie information</router-link></p>
          <!-- Cookie information.</p> -->
        </div>
        <div class="col-md-3">
          <button class="btn m-2 btn-subtle" text @click="deny">
            {{buttonTextDeny}}
          </button>
          <button class="btn m-2 btn-cta" @click="accept">
            {{buttonTextAccept}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Configuration } from '../configuration/configuration';

export default {
  props: {
    buttonTextAccept: {
      type: String,
      default: "Enable",
    },
    buttonTextDeny: {
      type: String,
      default: "Disable",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    if (!this.cookieConsentDismissed()) {
      this.isOpen = true;
    }
  },
  mounted() {
    if (this.getGDPR()) {
      this.accept();
    }
  },
  methods: {
    getGDPR() {
      return localStorage.getItem("GDPR:accepted") === "true";
    },

    cookieConsentDismissed() {
      return localStorage.getItem("CookieConsent:dismissed") === "true";
    },

    async accept() {
      this.isOpen = false;
      localStorage.setItem("GDPR:accepted", true);
      localStorage.setItem("CookieConsent:dismissed", true);

      // google tag manager
      if ((await Configuration.getEnvironment()).GoogleTagManagerId !== null) {
        (function (w, d, s, l, i) {
          /* eslint-disable */
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          const f = d.getElementsByTagName(s)[0];
          const j = d.createElement(s);
          const dl = l !== "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-5B3JT5W");
      }
    },
    deny() {
      this.isOpen = false;
      localStorage.setItem("GDPR:accepted", false);
      localStorage.setItem("CookieConsent:dismissed", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie {
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
  .block {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .cookie {
    max-width: 100%;
    left: 0px;
    .block {
      padding: 30px 32px;
      > div {
        display: flex;
        flex-direction: column;
        > div:last-child {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          flex: 1;
          min-width: 100%;
          max-width: 100%;
          padding-top: 20px;
          padding-bottom: 7px;
          button:last-child {
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>
