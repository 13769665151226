import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Dashboard = _resolveComponent("Dashboard")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_CookieConsent = _resolveComponent("CookieConsent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { applicationName: "Sesam Portal" }),
    _createVNode(_component_Dashboard),
    _createVNode(_component_Footer),
    _createVNode(_component_CookieConsent)
  ], 64))
}