import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54b17331"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-md-4" }
const _hoisted_2 = { class: "card card-dnvgl card-narrow mt-3 mb-3" }
const _hoisted_3 = ["href", "id"]
const _hoisted_4 = { class: "card-a" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: _ctx.url,
        id: _ctx.id,
        class: "card-anchor-top",
        target: "_blank"
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.imageSrc,
            alt: _ctx.name
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.name), 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
            ])
          ])
        ])
      ], 8, _hoisted_3)
    ])
  ]))
}