
import { defineComponent } from "vue";
import Header from "./Header.vue";
import Dashboard from "./Dashboard.vue";
import Footer from "./Footer.vue";
import CookieConsent from "./CookieConsent.vue";

export default defineComponent({
  name: 'Home',
  components: {
    Header,
    Dashboard,
    Footer,
    CookieConsent,
  },
});
