
import { defineComponent } from 'vue';
import { Configuration } from './configuration/configuration';

export default defineComponent({
  name: 'App',
  data() {
      return {
        googleTagManagerId: null
      };
    },

  async created() {
    let config = await Configuration.getEnvironment();
    this.googleTagManagerId = config.GoogleTagManagerId;
  }
});
