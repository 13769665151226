
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ApplicationCard',
  props: {
    name: String,
    description: String,
    image: String,
    url: String,
    id: String
  },
  computed: {
    imageSrc () {
      return require('@/assets/' + this.image );
    }
  }
});
