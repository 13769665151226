import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf0e91e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-md-4 mt-3 mb-3" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "length" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "video",
      id: _ctx.id
    }, [
      _createElementVNode("iframe", {
        width: "299",
        height: "153",
        src: _ctx.src,
        frameborder: "0",
        scrolling: "no",
        allowfullscreen: ""
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.length), 1)
    ], 8, _hoisted_2)
  ]))
}