import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.googleTagManagerId != null)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          src: 'https://www.googletagmanager.com/ns.html?id='+ _ctx.googleTagManagerId,
          height: "0",
          width: "0",
          style: {"display":"none","visibility":"hidden"}
        }, "\r\n  ", 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}