
import { defineComponent } from 'vue';
import ApplicationCard from './ApplicationCard.vue';
import VideoCard from './VideoCard.vue';
import FeaturedCard from './FeaturedCard.vue';
import DocumentationLink from './DocumentationLink.vue';
import HelpCard from './HelpCard.vue';
import { Configuration } from '../configuration/configuration';

export default defineComponent({
  name: 'Dashboard',
  components: {
    ApplicationCard,
    VideoCard,
    FeaturedCard,
    DocumentationLink,
    HelpCard
  },
  data: function () {
      return {
        exploreUrl: '',
        computeUrl: ''
      };
  },
  async created() {
      const configuration = await Configuration.getEnvironment();
      this.exploreUrl = configuration.ExploreUrl;
      this.computeUrl = configuration.ComputeUrl;
  }
});
