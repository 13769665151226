
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DocumentationLink',
  props: {
    url: String,
    title: String,
    id: String
  }
});
