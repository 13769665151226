
import { defineComponent } from "vue";
import Header from "./Header.vue";
import CookieStatement from "./CookieStatement.vue";
import Footer from "./Footer.vue";

export default defineComponent({
  name: 'CookieInformation',
  components: {
    Header,
    CookieStatement,
    Footer,
  },
});
