
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VideoCard',
  props: {
    youtubeId: String,
    brandCentralId: String,
    title: String,
    length: String,
    id: String
  },
  computed: {
    src () {
      if (this.brandCentralId) {
        return this.brandCentralId;
      } 

      return 'https://www.youtube.com/embed/' + this.youtubeId;
    }
  }
});
