
class MenuLink {
  constructor(public name: string, public url: string, public id: string) {}
}
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
  props: {
    links: {
      type: Array,
      default: function() {
        return [
          new MenuLink("Contact Support", "https://www.dnv.com/software/software-services/software-support.html", "footer-contact-support"),
          new MenuLink("Software Training", "https://www.dnv.com/software/training.html", "footer-training"),
          new MenuLink("Videos and Webinars", "https://www.dnv.com/software/video-webinar/index.html", "footer-videos-webinars"),
          new MenuLink("Cookie Information", "/CookieInformation", "footer-cookie-information"),
          new MenuLink("About DNV", "https://www.dnv.com/about/index.html", "footer-about-dnv")
        ];
      }
    }
  }
});
