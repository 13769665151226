
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FeaturedCard',
  props: {
    image: String,
    title: String,
    description: String,
    url: String,
    id: String
  },
  computed: {
    imageSrc () {
      return require('@/assets/' + this.image);
    }
  }
});
