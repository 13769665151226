
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HelpCard',
  props: {
    icon: String,
    title: String,
    description: String,
    url: String,
    id: String
  }
});
