import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-md-3 mb-4 pb-2" }
const _hoisted_2 = ["href", "id", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.url,
      id: _ctx.id,
      title: _ctx.title,
      target: "_blank"
    }, _toDisplayString(_ctx.title), 9, _hoisted_2)
  ]))
}