import { createWebHistory, createRouter } from 'vue-router'
import Home from '@/components/Home.vue'
import CookieInformation from '@/components/CookieInformation.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/CookieInformation',
        name: 'CookieInformation',
        component: CookieInformation
    },
  ]
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })
  
  export default router

